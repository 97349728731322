import { useState, useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IonButton, IonSpinner } from '@ionic/react';

import { UserContext } from '../context/UserContext';
import { StoreContext } from '../context/StoreContext';

import { Auth } from 'aws-amplify';
import { SignUpParams } from '@aws-amplify/auth';
import {customAlphabet} from 'nanoid';

type SignupFormFormValues = {
  email: string,
  firstName: string,
  lastName: string,
  notUnique: string
}

const SignupForm = () => {

  const [loading, setLoading] = useState(false);

  const {register, handleSubmit, clearErrors, setError, formState: {errors}} = useForm<SignupFormFormValues>();
  const history = useHistory();

  const { setUser } = useContext(UserContext);
  const { store }  = useContext(StoreContext);

  const handleAuthSignUp = async (email: string) => {
    // create random password for new users
    const nanoid = customAlphabet('1234567890abcdef', 10);
    const randomPassword = nanoid();

    const signUpReqObj: SignUpParams = {
      username: email,
      password: randomPassword,
      attributes: {
        email
      }
    }

    await Auth.signUp(signUpReqObj);
  }

  const onSubmit: SubmitHandler<SignupFormFormValues> = async (data: any, e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/create`, data);
      await handleAuthSignUp(data.email);
      setUser(res.data)
      store.set('user', res.data);
      history.push(`/verify`)
    } catch (err) {
      setLoading(false);
      if (axios.isAxiosError(err) && err.response) {
        setError('notUnique', {
          type: 'server',
          message: err.response.data.message
        })
      }
    }
    setLoading(false);
  }

  return (
    <form onSubmit={(e) => {
      clearErrors()
      handleSubmit(onSubmit)(e)
    }}>
      {errors.notUnique &&
        <div className="Banner Banner--error">
          {errors.notUnique.message}
        </div>
      }
      <div className="form__field">
        <label htmlFor="email">Email address</label>
        <input 
          id="email"
          placeholder="Enter your email address"
          type="email" 
          {...register('email')}
          className="input"
          autoComplete="off"
          required />
      </div>
      <div className="form__field">
        <label htmlFor="email">First name</label>
        <input 
          id="firstName"
          placeholder="First name"
          {...register('firstName')}
          className="input"
          autoComplete="off"
          required />
      </div>
      <div className="form__field">
        <label htmlFor="email">Last name</label>
        <input 
          id="lastName"
          placeholder="Last name"
          {...register('lastName')}
          className="input"
          autoComplete="off"
          required />
      </div>
      <IonButton type="submit" expand="block" onClick={() => clearErrors()}>
        {loading ? <IonSpinner /> : 'Create account'}
      </IonButton>
    </form>
  )
}


export default SignupForm;
