import { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
} from '@ionic/react';

import { setupIonicReact, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Storage } from '@ionic/storage';

import { Auth } from 'aws-amplify';

import Tabs from './pages/Tabs';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Landing from './pages/Landing';
import Verify from './pages/Verify';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/padding.css';

/* Theme variables */
import './theme/variables.css';

import './App.css';

import { IUser, UserAuthSession } from './models';

import { UserContext } from './context/UserContext';
import { StoreContext } from './context/StoreContext';

import { SplashScreen } from '@capacitor/splash-screen';

import mixpanel from 'mixpanel-browser';

const showSplashScreen = async () => {
  await SplashScreen.show();
};

mixpanel.init('2172c63088b7f1c5ca1709feaf53edc9');

setupIonicReact();

const App: React.FC = () => {

  const [user, setUser] = useState({} as IUser);
  const [userAuthSession, setUserAuthSession] = useState<UserAuthSession>();
  const [store, setStore] = useState({} as any);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isAuthenticated = async () => {
    try {
      await Auth.currentSession();
      setIsLoggedIn(true);
    } catch {
      setIsLoggedIn(false);
    }
  };

  async function createStorage() {
    const newStore = new Storage();
    await newStore.create();
    setStore(newStore);
  }

  const handleVerify = () => {
    setIsLoggedIn(true);
  }

  useEffect(() => {

    if (!Object.keys(store).length) {
      createStorage();
    }

    isAuthenticated();

    if (isPlatform('ios' || 'android')) {
      showSplashScreen();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[store, isLoggedIn, user])

  return (
    <IonApp>
      <IonReactRouter>
        <StoreContext.Provider value={{store, setStore}}>
          <UserContext.Provider value={{user, setUser, userAuthSession,setUserAuthSession}}>
            <IonRouterOutlet>
              <Route path="/t" render={() => {
                return isLoggedIn ? <Tabs /> : <Redirect to="/" />
              }}/>
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/verify" render={() => <Verify onVerify={handleVerify} />} />
              <Route path="/" exact render={() => {
                return isLoggedIn ? <Redirect to="/t" /> : <Landing />
              }} />
            </IonRouterOutlet>
          </UserContext.Provider>
        </StoreContext.Provider>
      </IonReactRouter>
    </IonApp>
  )
};

export default App;
