import { IonPage, IonImg, IonButton } from '@ionic/react';

import { Link } from 'react-router-dom';

const Landing = () => {

  const maintenance = false;

  return (
    <IonPage className="Landing">
      <div className="container">
        <div className="Landing__content">
          <IonImg src="assets/logo.svg" className="Landing__logo" />
          {maintenance ? 
            <div className="Banner">
              Contendo is down for maintenance
            </div>
              :
            <footer className="Landing__footer">
              <IonButton routerLink="signup" expand="block" className="Landing__action">Join Contendo</IonButton>
              <Link to="login">Already have account? Log In</Link>
            </footer>
          }
        </div>
      </div>
    </IonPage>
  )
}

export default Landing;
