import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  IonHeader,
  IonContent,
  IonList,
  IonImg,
  IonIcon,
  IonLabel,
  IonItem,
  IonPopover,
  IonButton,
  IonButtons,
  IonToolbar,
} from '@ionic/react';

import { StoreContext } from '../context/StoreContext';

import { Auth } from 'aws-amplify';

import { personCircleOutline } from 'ionicons/icons';

const AppHeader : React.FC = ({ children }) => {

  const [popover, setPopover] = useState({ showPopover: false, event: undefined});
  const { store }  = useContext(StoreContext);
  const history = useHistory();

  const handleSignOut = async () => {
    store.set('user', null);
    await Auth.signOut();
    history.push('/');
  }

  return (
    <header>
      <IonHeader>
        <IonToolbar>
          { children && children }
          <div className="centered-logo">
            <IonImg src="assets/logo-alt.svg" style={{width: '110px', margin: '0 auto'}} />
          </div>
          <IonButtons slot="end">
            <IonButton onClick={(e: any) => {
              e.persist();
              setPopover({ showPopover: true, event: e})
            }}>
              <IonIcon icon={personCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonPopover
            event={popover.event}
            isOpen={popover.showPopover}
            onDidDismiss={() => setPopover({ showPopover: false, event: undefined })}
            dismissOnSelect={true}>
            <IonContent scrollY={false}>
              <IonList>
                <IonItem lines="none" button={true} detail={false} onClick={handleSignOut}>
                  <IonLabel>Sign Out</IonLabel>
                </IonItem>
              </IonList>
            </IonContent>
          </IonPopover>
        </IonToolbar>
      </IonHeader>
    </header>
  )
}

export default AppHeader;
