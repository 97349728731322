import { useState } from 'react';
import axios from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IonButton, IonSpinner } from '@ionic/react';

import { Auth } from 'aws-amplify';
import { SignUpParams } from '@aws-amplify/auth';
import {customAlphabet} from 'nanoid';

import { isProd } from '../utility';

import mixpanel from 'mixpanel-browser';

type InviteFormFormValues = {
  email: string,
  firstName: string,
  lastName: string,
  notUnique: string
}

interface InviteFormProps {
  inviteId?: string;
  onInvited?: (player: any) => void;
}

const InviteForm = ({ inviteId, onInvited } : InviteFormProps) => {

  const [loading, setLoading] = useState(false);

  const {register, handleSubmit, clearErrors, reset, setError, formState: {errors, isSubmitSuccessful}} = useForm<InviteFormFormValues>();

  const handleAuthSignUp = async (email: string) => {
    // create random password for new users
    const nanoid = customAlphabet('1234567890abcdef', 10);
    const randomPassword = nanoid();

    const signUpReqObj: SignUpParams = {
      username: email,
      password: randomPassword,
      attributes: {
        email
      }
    }

    await Auth.signUp(signUpReqObj);
  }

  const onSubmit: SubmitHandler<InviteFormFormValues> = async (data: any, e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/create?team=${inviteId}`, data);
      const newPlayer = {
        ...data,
        playerId: res.data.id
      }
      handleAuthSignUp(data.email);
      onInvited && onInvited(newPlayer);
      reset()
      if (isProd()) {
        mixpanel.track('Player Invited');
        axios.post('https://discord.com/api/webhooks/962658467745792000/3WCKsxe4HCJKqlc3e2X1ZqUmbd8MVS0y2DyhGHO0jrsb0XU6lbBoUCbtDhNiuaBwirVh', {
          "content": `${data.email} invited to ${inviteId} (inviteId)`
        })
      }
    } catch (err) {
      setLoading(false);
      if (axios.isAxiosError(err) && err.response) {
        setError('notUnique', {
          type: 'server',
          message: err.response.data.message
        })
      }
    }
    setLoading(false);
  }

  return (
    <form onSubmit={(e) => {
      clearErrors()
      handleSubmit(onSubmit)(e)
    }}>
      {isSubmitSuccessful &&
        <div className="Banner Banner--success">
          Player added successfully... add another if you want.
        </div>
      }
      {errors.notUnique &&
        <div className="Banner Banner--error">
          {errors.notUnique.message}
        </div>
      }
      <div className="form__field">
        <label htmlFor="email">Player's email address</label>
        <input 
          id="email"
          placeholder="Enter their email address"
          type="email" 
          {...register('email')}
          className="input"
          autoComplete="off"
          required />
      </div>
      <div className="form__field">
        <label htmlFor="email">Player's first name</label>
        <input 
          id="firstName"
          placeholder="First name"
          {...register('firstName')}
          className="input"
          autoComplete="off"
          required />
      </div>
      <div className="form__field">
        <label htmlFor="email">Player's last name</label>
        <input 
          id="lastName"
          placeholder="Last name"
          {...register('lastName')}
          className="input"
          autoComplete="off"
          required />
      </div>
      <IonButton type="submit" expand="block" onClick={() => clearErrors()}>
        {loading ? <IonSpinner /> : 'Send Invite'}
      </IonButton>
    </form>
  )
}


export default InviteForm;
