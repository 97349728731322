import { useState, useEffect, useContext } from 'react';
import { IonPage, IonRouterOutlet } from '@ionic/react'
import { SWRConfig } from 'swr';

import { Redirect, Route } from 'react-router-dom';

import Teams from './Teams';
import TeamDetails from './TeamDetails';
import Account from './Account';

import { SeasonsContext } from '../context/SeasonsContext';
import { UserContext } from '../context/UserContext';
import { TeamsContext } from '../context/TeamsContext';
import { StoreContext } from '../context/StoreContext';

import { ISeason, ITeamDetails, ITeam } from '../models';

const Tabs = () => {

  const { setUser } = useContext(UserContext);
  const { store } = useContext(StoreContext);

  const [teams, setTeams] = useState([] as ITeam[]);
  const [activeTeam, setActiveTeam] = useState({} as ITeamDetails);
  const [activeSeason, setActiveSeason] = useState<ISeason | null>(null);

  async function getUserFromStorage() {
    const user = await store.get('user');
    setUser(user);
  }

  useEffect(() => {
    if (store) {
      getUserFromStorage();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store])

  return (
    <IonPage className="Landing">
      <TeamsContext.Provider value={{teams, setTeams, activeTeam, setActiveTeam}}>
        <SeasonsContext.Provider value={{ activeSeason, setActiveSeason}}>
          <SWRConfig value={{
            revalidateOnFocus: false
          }}>
            <IonRouterOutlet>
              <Route path="/t" render={() => <Teams />} exact={true} />
              <Route path="/t/account" component={Account} exact={true} />
              <Route path="/t/:id" render={() => <TeamDetails />} exact={true} />
              <Route exact path="/tabs">
                <Redirect to="/t/teams" />
              </Route>
            </IonRouterOutlet>
          </SWRConfig>
        </SeasonsContext.Provider>
      </TeamsContext.Provider>
    </IonPage>
  )
}

export default Tabs;
