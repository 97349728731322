import { useState } from 'react';
import {
  IonButton,
} from '@ionic/react';

import icon from '../assets/icon.svg';

import { isProd } from '../utility';

import mixpanel from 'mixpanel-browser';

interface HomeBannerProps {
  onAddToHome: () => void;
}

const HomeBanner = ( { onAddToHome } : HomeBannerProps) => {

  const [hidden, setHidden] = useState(false);

  const handleAddToHomeScreen = () => {
    setHidden(true);
    localStorage.setItem('dismissedHomeBanner', 'true');
    onAddToHome();
    if (isProd()) {
      mixpanel.track('Click Add To Home');
    }
  }

  const handleNoThanks = () => {
    setHidden(true);
    localStorage.setItem('dismissedHomeBanner', 'true');
    if (isProd()) {
      mixpanel.track('Click No Thanks to home screen');
    }
  }

  return (
    <div className="HomeBanner" hidden={hidden}>
      <img className="HomeBanner__icon" src={icon} alt="" width="32" height="32" />
      <div>
        <h4 className="HomeBanner__title">Add to your Home Screen</h4>
        <p className="HomeBanner__copy">Contendo is way better when it's one tap away</p>
        <footer className="HomeBanner__actions">
          <IonButton size="small" onClick={handleAddToHomeScreen}>Add to Home Screen</IonButton>
          <IonButton color="light" size="small" fill="clear" onClick={handleNoThanks}>No thanks</IonButton>
        </footer>
      </div>
    </div>
  )
}

export default HomeBanner;
