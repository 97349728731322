import { createContext, Dispatch, SetStateAction } from "react";

import { ISeason } from '../models';

interface ISeasonsContext {
  activeSeason: ISeason | null,
  setActiveSeason: Dispatch<SetStateAction<ISeason | null>>,
}

export const SeasonsContext = createContext<ISeasonsContext>({} as ISeasonsContext);
