import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {
  IonButtons,
  IonButton,
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { ITeam } from '../models';

import { isProd } from '../utility';

import mixpanel from 'mixpanel-browser';

const SPORTS = [
  'Football (Soccer)',
  'Rugby League',
  'Rugby Union',
  'Basketball',
  'Netball',
  'Australian Rules Football',
  'Other',
]

interface TeamsCreateProps {
  onDismiss: () => void;
  onCreateTeam: (team: ITeam) => void;
  userId: string;
}

const TeamsCreate = ({ onDismiss, userId, onCreateTeam } : TeamsCreateProps) => {

  const { register, handleSubmit } = useForm();

  let history = useHistory();

  const sportList = SPORTS.map((sport: string) => {
    const formatted = sport.toLowerCase().replace(/\s/g, '');
    return (
      <label htmlFor={formatted} className="input input--radio" key={`sport-${sport}`}>
        {sport}
        <input
          {...register("sport")}
          type="radio"
          id={formatted}
          value={sport}
          name="sport"
          required
        />
        <i></i>
      </label>
    )
  });

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    try {
      const body = {
        teamName: data.teamName,
        sports: data.sport,
        createdById: userId,
      }
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/team/create`, body)
      const team = {
        playerId: res.data.createdBy.id,
        teamId: res.data.id,
        teamName: data.teamName,
        sports: data.sport,
        dateCreated: new Date(),
        playersCount: 1,
        isAdmin: true,
      }

      onCreateTeam(team);
      onDismiss();

      if (isProd()) {
        mixpanel.track('Team Created', {
          "sport": data.sport
        });
        axios.post('https://discord.com/api/webhooks/959058338048520252/7YC7NGj0L3P9pYE0S02SyaHEkaVYHdYxL-VILSArampgYyi5Uaw390pxeYNPCfmEtUXP', {
          "content": `${data.teamName} (${data.sport})`
        })
      }

      history.push(`/t/${res.data.id}?isAdmin=true`)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    localStorage.setItem('onboarded', 'true');

    mixpanel.track('Create Team Presented');
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Create Team</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="form__field">
              <label htmlFor="teamName">Team name</label>
              <input autoComplete="off" id="teamName" placeholder="Enter team name" type="text" {...register("teamName")} className="input" required />
            </div>
            <div>
              <label>Team sport</label>
              <div className="TeamsCreate__sports">
                {sportList}
              </div>
            </div>
            <div className="ion-margin-top">
              <IonButton type="submit" expand="block">Create Team</IonButton>
            </div>
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default TeamsCreate;
