import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from '@ionic/react';

import { readableDate } from '../utility';

import { IMatch } from '../models';

interface MatchProps {
  match: IMatch | undefined | null;
  isPreview?: boolean;
  link?: string;
}

const Match = ({ match, isPreview, link } : MatchProps) => {

  return (
    <IonCard className="Match">
      <IonCardHeader className="Match__body">
        <div>
          <h4 className="Match__text">Votes required for the match against</h4>
          <IonCardTitle>{match?.opponent}</IonCardTitle>
          {match && <IonCardSubtitle>{readableDate(match.matchDatetime)}</IonCardSubtitle> }
        </div>
      </IonCardHeader>
    </IonCard>
  )
}

export default Match;
