import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR, {useSWRConfig} from 'swr';
import {
  IonPage,
  IonContent,
  IonFooter,
  IonButton,
  IonSpinner,
  useIonViewWillEnter,
  useIonModal,
} from '@ionic/react';

import { ITeam } from '../models';

import { fetcher } from '../utility';

import { UserContext } from '../context/UserContext';
import { TeamsContext } from '../context/TeamsContext';

import TeamList from '../components/TeamList';
import TeamsCreate from '../components/TeamsCreate';
import AddToHomeModal from '../components/AddToHomeModal';
import AppHeader from '../components/AppHeader';
import HomeBanner from '../components/HomeBanner';

const Teams = () => {

  const { user } = useContext(UserContext);
  const { teams, setTeams } = useContext(TeamsContext);
  const {mutate, cache} = useSWRConfig();

  const history = useHistory();

  const noThanks = localStorage.getItem('dismissedHomeBanner');
  
  useIonViewWillEnter(()=> {
    const res = cache.get(`${process.env.REACT_APP_API_URL}/users/${user?.email}/teams`);
    if(res){
      mutate(`${process.env.REACT_APP_API_URL}/users/${user?.email}/teams`);
    }
  })

  const { data: teamsData, error } = useSWR(
    user?.email
    ? `${process.env.REACT_APP_API_URL}/users/${user.email}/teams` 
    : null,
    fetcher
  )

  const handleCreateTeam = (team: ITeam) => {
    setTeams([...teams, team]);
    history.push(`/t/${team.teamId}?isAdmin=true`);
  }

  const Content = () => {
    if (error) return <div>Something went wrong</div>
    if (!teams) return <div className="full-screen-center"><IonSpinner /></div> 

    return (
      <div className="container">
        <TeamList teams={teams} />
      </div>
    )
  }

  useEffect(() => {

    const onboarded = localStorage.getItem('onboarded');
    const noTeams = teamsData && teamsData.length === 0;

    setTeams(teamsData);

    if (onboarded !== 'true' && noTeams) {
      presentCreate();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsData])

  const [presentHomeHow, dismissHomeHow] = useIonModal(AddToHomeModal, {
    onDismiss: () => dismissHomeHow(),
  });

  const [presentCreate, dismissCreate] = useIonModal(TeamsCreate, {
    onDismiss: () => dismissCreate(),
    onCreateTeam: (team: ITeam) => handleCreateTeam(team),
    userId: user?.id,
  });

  const modalOptions = {
    initialBreakpoint: 1,
    backdropBreakpoint: 0.2,
  }

  return (
    <IonPage>
      <AppHeader />
      <IonContent fullscreen>
        {noThanks !== 'true' &&
          <HomeBanner onAddToHome={() => presentHomeHow()} />
        }
        <div>
          <Content />
        </div>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonButton onClick={() => presentCreate(modalOptions)} expand="block" className="ion-margin-bottom">Create team</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Teams;
