import axios from 'axios';

import { format } from 'date-fns';

const fetcher = async (url: string) => {
  const res = await axios.get(url);
  return res.data;
}

const readableDate = (dateString: string) => {
  const date = new Date(dateString);
  const readable = format(date, 'EEE, d MMM');
  return readable;
}

const isProd = () => {
  const env = process.env.NODE_ENV;
  return env === 'production';
}

export { fetcher, readableDate, isProd };
