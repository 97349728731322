import {
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonButtons,
  IonToolbar,
} from '@ionic/react';

interface PointsModalProps {
  onDismiss: () => void;
  onPointsSelected: (points: number) => void;
  playerName: string;
  selected: number;
}

const PointsModal = ({ onDismiss, onPointsSelected, playerName, selected } : PointsModalProps) => {

  return (
    <>
      <IonHeader>
        <IonToolbar mode="md">
          <IonTitle>How many points for {playerName}?</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="desktop-center">
          <div className="container">
            <div className="PointsModal__options">
              <div className={` PointsModal__option ${selected === 3 ? 'selected' : ''}`} onClick={() => onPointsSelected(3)}>3</div>
              <div className={` PointsModal__option ${selected === 2 ? 'selected' : ''}`} onClick={() => onPointsSelected(2)}>2</div>
              <div className={` PointsModal__option ${selected === 1 ? 'selected' : ''}`} onClick={() => onPointsSelected(1)}>1</div>
            </div>
          </div>
        </div>
      </IonContent>
    </>
  )
}

export default PointsModal;
