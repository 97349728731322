import { useEffect, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonButtons,
  IonToolbar,
} from '@ionic/react';

import { isPlatform } from '@ionic/react';

import how1 from '../assets/how-ios-1.jpg';
import how2 from '../assets/how-ios-2.jpg';
import how3 from '../assets/how-ios-3.jpg';

import how4 from '../assets/how-android-1.jpg';
import how5 from '../assets/how-android-2.jpg';
import how6 from '../assets/how-android-3.jpg';

interface AddToHomeProps {
  onDismiss: () => void;
}

const AddToHome = ({ onDismiss } : AddToHomeProps) => {

  const [selectedTab, setSelectedTab] = useState('ios');

  useEffect(() => {
    setSelectedTab(isPlatform('ios') ? 'ios' : 'android');
  }, []);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add to your Home Screen</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <ul className={`unstyled Tabs Tabs--${selectedTab}`}>
            <li onClick={() => setSelectedTab('ios')}>iOS</li>
            <li onClick={() => setSelectedTab('android')}>Android</li>
          </ul>
          <div hidden={selectedTab === 'android'}>
            <h3 className="pressed">1. Tap share icon</h3>
            <p>While using Contendo in Safari, tap the share icon at the bottom of the screen.</p>
            <img src={how1} alt="" style={{marginBottom: '20px', width: '100%'}} />
            <h3 className="pressed">2. Tap Add to Home Screen</h3>
            <p>In the menu that opens, scroll past the social options and tap 'Add to Home Screen'</p>
            <img src={how2} alt="" style={{marginBottom: '20px', width: '100%'}}/>
            <h3 className="pressed">3. Save to Home Screen</h3>
            <p>Leave the name as Contendo and hit 'Add'. The Contendo icon will now appear on your Home Screen next to your other apps.</p>
            <img src={how3} alt="" style={{marginBottom: '20px', width: '100%'}} />
          </div>
          <div hidden={selectedTab === 'ios'}>
            <h3 className="pressed">1. Tap share icon</h3>
            <p>While using Contendo in Chrome, tap the three vertical dots in the top right of the screen.</p>
            <img src={how4} alt="" style={{marginBottom: '20px', width: '100%'}} />
            <h3 className="pressed">2. Tap Add to Home Screen</h3>
            <p>In the menu that opens, tap 'Add to Home Screen'</p>
            <img src={how5} alt="" style={{marginBottom: '20px', width: '100%'}}/>
            <h3 className="pressed">3. Save to Home Screen</h3>
            <p>Leave the name as Contendo and hit 'Add'. The Contendo icon will now appear on your Home Screen next to your other apps.</p>
            <img src={how6} alt="" style={{marginBottom: '20px', width: '100%'}} />
          </div>
        </div>
      </IonContent>
    </>
  )
}

export default AddToHome;
