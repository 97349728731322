import useSWR from 'swr';
import { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  IonButton, 
  IonButtons,
  IonIcon,
  IonSpinner,
  IonContent,
  IonPage,
  useIonModal,
  useIonViewWillLeave,
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';

import LeaderboardTable from '../components/LeaderboardTable';
import AdminActions from '../components/AdminActions';
import VoteModal from '../components/VoteModal';
import VotePrompt from '../components/VotePrompt';
import AppHeader from '../components/AppHeader';

import { fetcher } from '../utility';
import { useQuery } from '../hooks/useQuery';

import { ISeason } from '../models';

import { SeasonsContext } from '../context/SeasonsContext';
import { TeamsContext } from '../context/TeamsContext';

import { IMatch } from '../models';

const TeamDetails = () => {

  const pageRef = useRef<HTMLElement>(null);

  const [mustVote, setMustVote] = useState(false);
  const [prevMatch, setPrevMatch] = useState<IMatch | null>();
  const [prevMatchRound] = useState(0);
  
  let { id } = useParams<{ id: string}>();
  const { data: teamData, error: teamDataError } = useSWR(`${process.env.REACT_APP_API_URL}/team/${id}/details`, fetcher)
  const { data: seasonsData, error: seasonsDataError } = useSWR(`${process.env.REACT_APP_API_URL}/team/${id}/fixtures`, fetcher)

  const { activeSeason, setActiveSeason }  = useContext(SeasonsContext);
  const { activeTeam, setActiveTeam }  = useContext(TeamsContext);

  let query = useQuery();
  const isAdmin = query.get("isAdmin");
  const playerId = query.get("playerId");

  const [presentModal, dismissModal] = useIonModal(VoteModal, {
    teamName: activeTeam?.teamName,
    seasonId: activeSeason?.id,
    playerId: playerId,
    prevMatch: prevMatch,
    players: activeTeam?.playersList,
    onVotesSubmitted: () => handleVoteSubmitted(),
    onVotesCancelled: () => handleVoteCancelled()
  });

  const modalOptions = {
    initialBreakpoint: 1,
    backdropBreakpoint: 0.2,
  }

  const handleVoteCancelled = () => {
    dismissModal();
  }

  const handleVoteSubmitted = () => {
    dismissModal();
    setMustVote(false);
  }

  const handleVoteProceed = () => {
    setMustVote(false);
    presentModal(modalOptions);
  }

  const handleSuccess = (data: any) => {
    if (!data) {
      setMustVote(true);
    }
  }

  useSWR(
    prevMatch && playerId && activeSeason?.id
    ? `${process.env.REACT_APP_API_URL}/poll-history/${playerId}?seasonId=${activeSeason?.id}&matchId=${prevMatch!.id}`
    : null,
    fetcher,
    {
      onSuccess: handleSuccess,
    }
  )

  useEffect(() => {
    if (seasonsData) {
      const active = seasonsData.find((season: ISeason) => {
        return season.dateCreated && !season.completionDate;
      });

      setActiveSeason(active);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonsData])

  useEffect(() => {
    if (activeSeason) {
      const prevMatch = activeSeason?.matches.reduce((a, b) => {
        return new Date(a.matchDatetime) > new Date(b.matchDatetime) ? a : b;
      });

      setPrevMatch(prevMatch);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSeason])

  useEffect(() => {

    if (teamData) {
      setActiveTeam(teamData);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData])

  useIonViewWillLeave(() => {
    dismissModal();
    setMustVote(false);
    setPrevMatch(null);
  })

  const Content = () => {
    if (teamDataError || seasonsDataError) return <div className="full-screen-center">Something went wrong</div>
    if (!teamData || !seasonsData) return <div className="full-screen-center"><IonSpinner /></div> 

    return (
      <div className="container">
        <LeaderboardTable playerId={playerId} isAdmin={isAdmin === 'true'} team={activeTeam} round={prevMatchRound} />
      </div>
    )
  }

  return (
    <IonPage className="Details" ref={pageRef}>
      <AppHeader>
        <IonButtons slot="start">
          <IonButton routerLink="/t" routerDirection="back">
            <IonIcon slot="start" icon={arrowBackOutline} />
          </IonButton>
        </IonButtons>
      </AppHeader>
      <IonContent fullscreen>
        <Content />
      </IonContent>
      {!teamDataError && isAdmin === "true" && activeTeam?.playersList?.length > 2 && <AdminActions hidden={mustVote} playerId={playerId} /> }
      {prevMatch && mustVote &&
        <VotePrompt match={prevMatch} onConfirm={handleVoteProceed} onCancel={() => setMustVote(false)}/>
      }
    </IonPage>
  );
};

export default TeamDetails;
