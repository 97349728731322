import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import {ILeaderboardEntry, ITeamDetails} from "../models";
import useSWR from 'swr';

import differenceBy from 'lodash.differenceby';
import sortBy from 'lodash.sortby';
import sum from 'lodash.sum';

import { IonToggle, IonLabel, useIonToast } from '@ionic/react';

import { fetcher, isProd } from '../utility';

import { UserContext } from '../context/UserContext';
import { TeamsContext } from '../context/TeamsContext';
import { SeasonsContext } from '../context/SeasonsContext';

import { Clipboard } from '@capacitor/clipboard';

import TeamMenu from '../components/TeamMenu';
import InviteForm from '../components/InviteForm';

import mixpanel from 'mixpanel-browser';

interface LeaderBoardTableProps {
  team: ITeamDetails;
  round?: number;
  isAdmin?: boolean;
  playerId?: string | null;
}

interface LeaderBoardRowProps {
  player: ILeaderboardEntry;
  position: number;
  masked: boolean | null | undefined;
}

const LeaderboardRow = ({ player, position, masked} : LeaderBoardRowProps) => {

  let threeVotes = 0;
  let twoVotes = 0;
  let oneVotes = 0;
  let totalScore = 0;

  const votes = player.vote;

  if (votes) {
    const votesAsNumbers = votes.map(v => parseInt(v))
    for(var i=0; i < votesAsNumbers.length; i++){
        if(votesAsNumbers[i] === 3)
           threeVotes++;
        if(votesAsNumbers[i] === 2)
           twoVotes++;
        if(votesAsNumbers[i] === 1)
           oneVotes++;
    }
    totalScore = (threeVotes * 3) + (twoVotes * 2) + oneVotes;
  }

  return (
    <tr className="Leaderboard__row">
      <td className="Leaderboard__player">
        <i className="Leaderboard__spot">{position}.</i>
        {masked ? <span className="Leaderboard__mask"></span> : <span>{player.playerName}</span>}
      </td>
      <td>
        {threeVotes}
      </td>
      <td>
        {twoVotes}
      </td>
      <td>
        {oneVotes}
      </td>
      <td>
        {totalScore}
      </td>
    </tr>
  )
}

const LeaderboardTable = ({ team, round, isAdmin, playerId } : LeaderBoardTableProps) => {

  const { user } = useContext(UserContext);
  const { activeTeam, setActiveTeam } = useContext(TeamsContext);
  const { activeSeason, setActiveSeason }  = useContext(SeasonsContext);

  const [leaderboard, setLeaderboard] = useState<any[]>([])

  const [presentToast] = useIonToast();

  const { data: votedPlayers } = useSWR(
    activeSeason?.id
    ? `${process.env.REACT_APP_API_URL}/match-poll/${activeSeason?.id}/leaderboard`
    : null,
    fetcher
  )

  const playersList = leaderboard?.map((p, i) => {
    return <LeaderboardRow masked={activeSeason?.private && !isAdmin} player={p} position={i + 1} />
  })

  const players = team?.playersList;

  const handleInviteCopy = async () => {
    await Clipboard.write({ string: `https://getcontendo.com/invite/${activeTeam?.inviteId}` });
    presentToast('Copied to clipboard', 3000);
    if (isProd()) {
      mixpanel.track('Copy Invite', {
        "userId": user?.id,
        "teamName": team.teamName,
        "sport": team.sports
      });
    }
  }

  const handlePrivateChange = async (e: any) => {
    if (activeSeason) {
      try {
        const newSeason = {...activeSeason, private: e.detail.checked};
        setActiveSeason(newSeason);
        await axios.patch(`${process.env.REACT_APP_API_URL}/season/${activeSeason.id}/editSeason`, {
          private: e.detail.checked,
        })

        if (isProd()) {
          mixpanel.track('Toggle Private', {
            "private": e.detail.checked
          });
          axios.post('https://discord.com/api/webhooks/959058338048520252/7YC7NGj0L3P9pYE0S02SyaHEkaVYHdYxL-VILSArampgYyi5Uaw390pxeYNPCfmEtUXP', {
            "content": `${team.teamName} set private set to ${e.detail.checked}`
          })
        }
      } catch {
        // set back to value before change
        const newSeason = {...activeSeason, private: !e.detail.checked};
        setActiveSeason(newSeason);
      }
    }
  }

  const handleInvited = (player: any) => {
    const newPlayers = [...activeTeam?.playersList, player];
    const newActiveTeam = {
      ...activeTeam,
      playersList: newPlayers
    }
    setActiveTeam(newActiveTeam);
  }

  useEffect(() => {
    if(votedPlayers?.length) {

      const unvoted = differenceBy(players, votedPlayers, 'playerId');

      const unvotedFormatted = unvoted.map((player) => {
        return {
          playerId: player.playerId,
          playerName: player.firstName + ' ' + player.lastName
        }
      })

      const sortedVotes = sortBy(votedPlayers, [(p) => sum(p.vote.map(Number))]).reverse();

      setLeaderboard([...sortedVotes, ...unvotedFormatted]);
    } else {

      const formatted = players?.map((player) => {
        return {
          playerId: player.playerId,
          playerName: player.firstName + ' ' + player.lastName
        }
      })

      setLeaderboard(formatted);
      
    }

  }, [votedPlayers, players])

  return (
    <div>
      <header className="Leaderboard__header">
        <TeamMenu playerId={playerId} isAdmin={isAdmin} />
        {isAdmin && activeSeason?.id &&
          <div className="HideLeaderboard">
            <IonLabel className="HideLeaderboard__label">
              <label className="flat">Private mode</label>
              <small className="HideLeaderboard__tip">Only admins can see player names</small>
            </IonLabel>
            <IonToggle checked={activeSeason?.private || false} onIonChange={handlePrivateChange} />
          </div>
        }
      </header>
      {!isAdmin && activeSeason?.private &&
        <div className="Banner">This season is currently in Private Mode, during this time player names are hidden.</div>
      }
      {players?.length >= 2 ? (
        <div>
          <table className="Leaderboard__table">
            <thead>
              <tr>
                <th className="Leaderboard__players">
                  { round ? `Round ${round}` : 'Players'}
                </th>
                <th>
                  3
                </th>
                <th>
                  2
                </th>
                <th>
                  1
                </th>
                <th className="Leaderboard__total">
                  T
                </th>
              </tr>
            </thead>
            <tbody>
              {playersList}
            </tbody>
          </table>
        </div>
      )
        :
      (
        <div className="Leaderboard__empty">
          <div className="Leaderboard__invite">
            <h2>Invite your players</h2>
            <p>Share this link in your group chat to get your players to join the team.</p>
            <p className="Invite" onClick={handleInviteCopy}>
              getcontendo.com/invite/{activeTeam?.inviteId}
              <span className="Invite__action">Copy</span>
            </p>
          </div>
          <h2>Or, create them yourself</h2>
          <InviteForm inviteId={team.inviteId} onInvited={handleInvited} />
        </div>
      )
      }
    </div>
  )
}

export default LeaderboardTable;
