
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import UpdateForm from '../components/UpdateForm';

const Account = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Your account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="desktop-center">
          <div className="container container--small ion-margin-top">
            <div className="ion-padding-bottom">
              <h1>Tell us your name</h1>
              <p>Please provide your name for the Leaderboard.</p>
            </div>
            <UpdateForm />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Account;
