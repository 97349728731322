import { createContext, Dispatch, SetStateAction } from "react";

import { ITeamDetails, ITeam } from '../models';

interface ITeamsContext {
  teams: ITeam[],
  activeTeam: ITeamDetails,
  setTeams: Dispatch<SetStateAction<ITeam[]>>,
  setActiveTeam: Dispatch<SetStateAction<ITeamDetails>>
}

export const TeamsContext = createContext<ITeamsContext>({} as ITeamsContext);
