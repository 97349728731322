import { useContext, useEffect, useState } from 'react';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonImg,
  IonSpinner,
  IonRouterLink,
} from '@ionic/react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {Auth} from 'aws-amplify';
import { UserContext } from '../context/UserContext';

type VerifyProps = {
  onVerify: () => void;
}

const Verify = ({ onVerify } : VerifyProps) => {

  const [loading, setLoading] = useState(false);
  const [resendCodeLoading, setResendCodeLoading] = useState(false);

  const { user, userAuthSession,setUserAuthSession } = useContext(UserContext);
  const { register, handleSubmit, clearErrors, setError, formState: { errors }, reset } = useForm();

  const history = useHistory();

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      const isAuthenticated = await verifyAuth(data.code);

      if(!isAuthenticated) {
        //TODO: Error UI
        console.log('Code input is wrong.')
        setError('badCode', {
          type: 'manual',
          message: 'Code is not valid. Please check you copied correctly.'
        });
        setLoading(false);
        return;
      } else {
        onVerify();
      }

      if (user && !user.firstName) {
        history.push('/t/account')
      } else {
        history.push('/t')
      }
    } catch(e) {
      if((e as Error).message === 'Invalid session for the user.'){
        setError('error', {
          type: 'manual',
          message: 'Session has expired. Please click resend code button to get new verification code.'
        })
      }else {
        setError('error', {
          type: 'manual',
          message: 'Something went wrong. Please click back to login again.'
        });
      }

      setLoading(false);
    }
  }

  const verifyAuth = async (code: string): Promise<boolean> => {
    await Auth.sendCustomChallengeAnswer(userAuthSession,code);
    try {
      await Auth.currentSession();
      return true;
    } catch {
      return false;
    }
  }

  const isAuthenticated = async () => {
    try {
      await Auth.currentSession();
    } catch {
      //do something
    }
  };

  const handleResendCode = async () => {
    setResendCodeLoading(true);
    reset({code:''});
    try {
      const cognitoUser = await Auth.signIn(user?.email as string);
      setUserAuthSession(cognitoUser);
    } catch (error) {
        setError('error', {
          type: 'manual',
          message: 'Something went wrong. Please click back to login again.'
        });
    }
    setResendCodeLoading(false);
  }

  useEffect(() => {
    isAuthenticated();
  }, [])
  

  return (
    <IonPage>
      <IonHeader mode="md">
        <IonToolbar>
          <div className="centered-logo">
            <IonRouterLink href="/">
              <IonImg src="assets/logo-alt.svg" style={{width: '110px', margin: '0 auto'}} />
            </IonRouterLink>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="desktop-center">
          <div className="container container--small ion-margin-top">
            <div className="ion-padding-bottom">
              <h1>Verify your account</h1>
              <p>We have sent a 6-digit code to the email address you just provided.</p>
              <p>Copy and paste the code below to verify your account.</p>
            </div>
            <form onSubmit={(e) => {
              clearErrors();
              handleSubmit(onSubmit)(e)
            }}>
              {errors.badCode &&
                <div className="Banner Banner--error">
                  {errors.badCode.message}
                </div>
              }
              {errors.error &&
                <div className="Banner Banner--error">
                  {errors.error.message}
                </div>
              }
              <div className="form__field">
                <label htmlFor="code">6-digit code</label>
                <input 
                  id="code"
                  placeholder="Enter your 6-digit code"
                  type="code" 
                  {...register('code')}
                  className="input"
                  autoComplete="off"
                  required />
              </div>
              <IonButton type="submit" expand="block">
                {loading ? <IonSpinner /> : 'Log In'}
              </IonButton>
              <IonButton size="small" color="light" expand='block' onClick={handleResendCode} className="ion-margin-top">{resendCodeLoading ? <IonSpinner color='light' /> : 'Resend code'}</IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Verify;
