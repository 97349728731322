import Match from './Match';
import { IonBackdrop, IonButton } from '@ionic/react';

import { IMatch } from '../models'

interface VotePromptProps {
  match: IMatch;
  onConfirm: () => void;
  onCancel: () => void;
}

const VotePrompt = ({ match, onConfirm, onCancel } : VotePromptProps) => {

  return (
    <div>
      <div className="Poll">
        <Match match={match} />
        <footer className="Poll__footer">
          <IonButton expand="block" onClick={() => onConfirm()}>Vote Now</IonButton>
          <IonButton expand="block" color="light" onClick={() => onCancel()}>Vote Later</IonButton>
        </footer>
      </div>
      <IonBackdrop />
    </div>
  )

};

export default VotePrompt;
