import {
  IonBackButton,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonImg,
} from '@ionic/react';

import SignupForm from '../components/SignupForm';

const Signup = () => {

  return (
    <IonPage>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <div className="centered-logo">
            <IonImg src="assets/logo-alt.svg" style={{width: '110px', margin: '0 auto'}} />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="desktop-center">
          <div className="container container--small ion-margin-top">
            <h2>Join Contendo</h2>
            <SignupForm />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Signup;
