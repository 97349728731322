import { useContext } from 'react';

import { SeasonsContext } from '../context/SeasonsContext';
import { TeamsContext } from '../context/TeamsContext';

import RequestModal from './RequestModal'

import { ISeason, IMatch } from '../models';

import {
  IonButton,
  IonFooter,
  useIonModal,
} from '@ionic/react';

interface AdminActionsProps {
  playerId: string | null;
  hidden: boolean;
}

const AdminActions = ({ playerId, hidden } : AdminActionsProps) => {

  const { activeTeam }  = useContext(TeamsContext);
  const { activeSeason, setActiveSeason }  = useContext(SeasonsContext);

  const [presentModal, dismissModal] = useIonModal(RequestModal, {
    onDismiss: () => dismissModal(),
    onCreateSeason: (season: ISeason) => handleCreateSeason(season),
    onCreateMatch: (match: IMatch) => handleCreateMatch(match),
    teamId: activeTeam?.id,
    playerId,
    teamName: activeTeam?.teamName,
    seasonId: activeSeason?.id,
    recipients: activeTeam?.playersList?.map(p => p.email)
  });

  const modalOptions = {
    onDidDismiss: () => dismissModal(),
    breakpoints: [0, 0.2, 0.5, 1],
    initialBreakpoint: 0.5,
    backdropBreakpoint: 0.2,
  }

  const handleCreateSeason = (season: ISeason) => {
    setActiveSeason(season);
  }

  const handleCreateMatch = (match: IMatch) => {
    console.log(match);
    console.log({ ...activeSeason });
  }

  return (
    <IonFooter className="ion-padding" style={{ transform: `translateY(${hidden ? '100%' : '0'})`}}>
      <IonButton expand="block" onClick={() => { presentModal(modalOptions)}} className="ion-margin-bottom">Request votes</IonButton>
    </IonFooter>
  )
}

export default AdminActions;
