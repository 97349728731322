import axios from 'axios';
import { useContext, useState } from 'react';
import {
  IonBackButton,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonImg,
  IonSpinner,
} from '@ionic/react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../hooks/useQuery';

import { StoreContext } from '../context/StoreContext';
import { UserContext } from '../context/UserContext';
import { Auth } from 'aws-amplify';

const Login = () => {

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, clearErrors, setError, formState: { errors } } = useForm();

  const history = useHistory();
  const query = useQuery();

  const { setUser, setUserAuthSession }  = useContext(UserContext);
  const { store }  = useContext(StoreContext);

  const email = query.get("email");

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    setLoading(true);
    try  {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/${data.email}`)
      setUser(res.data)
      store.set('user', res.data);
      const cognitoUser = await Auth.signIn(data.email);
      setUserAuthSession(cognitoUser);
      history.push('/verify')
    } catch (err: any) {
      setError('noUser', {
        type: 'manual',
        message: err.response?.data?.message
      });
    }
    setLoading(false);
  }

  return (
    <IonPage>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <div className="centered-logo">
            <IonImg src="assets/logo-alt.svg" style={{width: '110px', margin: '0 auto'}} />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="desktop-center">
          <div className="container container--small ion-margin-top">
            <h2>Login</h2>
            <form onSubmit={(e) => {
              clearErrors();
              handleSubmit(onSubmit)(e)
            }}>
              {errors.noUser &&
                <div className="Banner Banner--error">
                  {errors.noUser.message}
                </div>
              }
              <div className="form__field">
                <input 
                  id="email"
                  placeholder="Enter your email address"
                  defaultValue={email ? email : ''}
                  type="email" 
                  {...register('email')}
                  className="input"
                  autoComplete="off"
                  required />
              </div>
              <IonButton type="submit" expand="block">
                {loading ? <IonSpinner /> : 'Log In'}
              </IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Login;
