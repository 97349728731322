import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import {
  IonPopover,
  IonList,
  IonItem,
  IonIcon,
  IonButtons,
  IonButton,
  IonLabel,
  IonContent,
  useIonAlert,
  useIonToast,
  useIonModal,
} from '@ionic/react';

import { chevronDownOutline } from 'ionicons/icons';

import { Clipboard } from '@capacitor/clipboard';

import { isProd } from '../utility';

import { ITeamPlayer } from '../models';

import mixpanel from 'mixpanel-browser';

import { SeasonsContext } from '../context/SeasonsContext';
import { TeamsContext } from '../context/TeamsContext';

import TeamInviteModal from './TeamInviteModal';
import TeamEditModal from './TeamEditModal';

interface TeamMenuProps {
  isAdmin?: boolean;
  playerId?: string | null;
}

const TeamMenu = ({ isAdmin, playerId } : TeamMenuProps) => {

  const history = useHistory();
  const [present] = useIonAlert();
  const [presentToast] = useIonToast();

  const [open, setOpen] = useState(false);

  const { activeSeason, setActiveSeason }  = useContext(SeasonsContext);
  const { activeTeam, setActiveTeam, teams, setTeams }  = useContext(TeamsContext);

  const handleInviteCopy = async () => {
    setOpen(false);
    await Clipboard.write({ string: `https://getcontendo.com/invite/${activeTeam?.inviteId}` });
    if (isProd()) {
      mixpanel.track('Copy Invite', {
        "source": "detailsHeader"
      })
    }
    presentToast('Copied to clipboard', 3000);
  }

  const handleConfirmSeasonEnd = () => {
    setOpen(false);
    present({
      header: 'Are you sure you want to reset the season?',
      message: 'Resetting the season can not be undone and will reset leaderboard positions.',
      buttons: [
        'Cancel',
        { text: 'Reset season', handler: handleResetSeason }
      ]
    })
  }

  const handleConfirmDeleteTeam = () => {
    setOpen(false);
    present({
      header: 'Are you sure you want to delete the team?',
      message: 'Deleting the team will permanantly remove it for yourself and all players.',
      buttons: [
        'Cancel',
        { text: 'Delete Team', handler: handleDeleteTeam }
      ]
    })
  }

  const handleResetSeason = async () => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/season/${activeSeason?.id}/endseason`)
    setActiveSeason(null);
  }

  const handleDeleteTeam = async () => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/team/${activeTeam?.id}/delete`)
    const newTeams = teams.filter(t => t.teamId !== activeTeam.id);
    setTeams(newTeams);
    setActiveSeason(null);
    history.replace('/t');
  }

  const handleInvited = (player: ITeamPlayer) => {
    const newPlayers = [...activeTeam?.playersList, player];
    const newActiveTeam = {
      ...activeTeam,
      playersList: newPlayers
    }
    setActiveTeam(newActiveTeam);
  }

  const handlePlayerDeleted = async (player: ITeamPlayer) => {
    const newPlayers = activeTeam?.playersList.filter(p => p.playerId !== player.playerId)
    const newActiveTeam = {
      ...activeTeam,
      playersList: newPlayers
    }
    setActiveTeam(newActiveTeam);
    console.log(newActiveTeam);
  }

  const [presentInvite, dismissInvite] = useIonModal(TeamInviteModal, {
    id: activeTeam?.inviteId,
    onDismiss: () => dismissInvite(),
    onInvited: (player: ITeamPlayer) => handleInvited(player)
  });

  const [presentEdit, dismissEdit] = useIonModal(TeamEditModal, {
    onDismiss: () => dismissEdit(),
    team: activeTeam,
    playerId,
    onPlayerDeleted: (player: ITeamPlayer) => handlePlayerDeleted(player),
  });

  return (
    <div>
      <div className="TeamTitle" id="trigger-button">
        <h1 className="TeamTitle__text">{activeTeam.teamName}</h1>
        <IonButtons>
          <IonButton>
            <IonIcon icon={chevronDownOutline} />
          </IonButton>
        </IonButtons>
      </div>
      <IonPopover isOpen={open} trigger="trigger-button" dismissOnSelect={true}>
        <IonContent scrollY={false}>
          <IonList>
            {isAdmin && activeTeam?.playersList?.length > 1 &&
              <IonItem button={true} detail={false} onClick={() => presentEdit()}>
                <IonLabel>Edit Team</IonLabel>
              </IonItem>
            }
            {isAdmin && activeTeam?.playersList?.length > 1 &&
              <IonItem button={true} detail={false} onClick={() => presentInvite()}>
                <IonLabel>Add Players</IonLabel>
              </IonItem>
            }
            <IonItem button={true} detail={false} onClick={handleInviteCopy}>
              <IonLabel>Copy Invite Link</IonLabel>
            </IonItem>
            {isAdmin && activeSeason &&
              <IonItem button={true} detail={false} onClick={handleConfirmSeasonEnd}>
                <IonLabel>Reset Season</IonLabel>
              </IonItem>
            }
            {isAdmin &&
              <IonItem lines="none" button={true} detail={false} onClick={handleConfirmDeleteTeam}>
                <IonLabel>Delete Team</IonLabel>
              </IonItem>
            }
          </IonList>
        </IonContent>
      </IonPopover>
    </div>
  )
}

export default TeamMenu;
