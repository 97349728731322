import { useState, useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonButton,
  IonFooter,
  IonSpinner,
  IonPage,
  useIonModal,
} from '@ionic/react';

import axios from 'axios';

import mixpanel from 'mixpanel-browser';

import { close } from 'ionicons/icons';

import {IMatch, ITeamPlayer} from '../models';

import PlayerList from './PlayerList';
import PointsModal from './PointsModal';

import { isProd } from '../utility';

interface VoteModalProps {
  teamName: string;
  seasonId: string;
  playerId: string;
  prevMatch: IMatch;
  players: ITeamPlayer[];
  onVotesSubmitted: () => void;
  onVotesCancelled: () => void;
}

interface IVotingTeamPlayer extends ITeamPlayer {
  votes?: number;
}

const VoteModal = ({ teamName, seasonId, playerId, prevMatch, players, onVotesSubmitted, onVotesCancelled } : VoteModalProps) => {

  const emptyVote = {
    playerId: '',
    firstName: '',
    lastName: '',
    email: ''
  };

  const [votedPlayers, setVotedPlayers] = useState<ITeamPlayer[]>([
    emptyVote,
    emptyVote,
    emptyVote
  ]);

  const [selectedPlayer, setSelectedPlayer] = useState<IVotingTeamPlayer | undefined>();
  const [loading, setLoading] = useState(false);

  const [presentPointsModal, dismissPointsModal] = useIonModal(PointsModal, {
    onDismiss: () => dismissPointsModal(),
    onPointsSelected: (points: number) => updateVotedPlayers(selectedPlayer!, points),
    playerName: selectedPlayer?.firstName,
    selected: selectedPlayer?.votes,
  });

  const modalOptions = {
    initialBreakpoint: 1,
    backdropBreakpoint: 0.2,
  }

  const handleVote = (player: ITeamPlayer) => {
    setSelectedPlayer(player);
    presentPointsModal(modalOptions);
  }

  const updateVotedPlayers = (player: IVotingTeamPlayer, points: number) => {
    //clone voted players to mutate
    const newVoted = [...votedPlayers]

    // remove if reselecting same points else add player to voted list
    if (player.votes === points) {
      newVoted.splice(points - 1, 1, emptyVote);
    } else {
      newVoted.splice(points - 1, 1, player);
    }

    // update voted players with mutated data
    setVotedPlayers(newVoted);

    // close modal
    dismissPointsModal();
  }

  const submitVote = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      seasonId: seasonId,
      voterId: playerId,
      matchId: prevMatch!.id,
      votes: [
        {
          playerId: votedPlayers[0].playerId,
          vote: "1"
        },
        {
          playerId: votedPlayers[1].playerId,
          vote: "2"
        },
        {
          playerId: votedPlayers[2]!.playerId,
          vote: "3"
        }
      ]
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/match-poll/vote`, body)
      onVotesSubmitted();
      if (isProd()) {
        mixpanel.track('Votes Submitted');
        axios.post('https://discord.com/api/webhooks/962125601936920676/SnpHCcdlTuggIGCrlNyXvHE1rRAUS464O81Wodbv9lH_G9VEh6WX_4YDrt03V7wbpJrR', {
          "content": `3, 2, 1 submitted for ${teamName}`
        })
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleDismiss = () => {
    onVotesCancelled();
    setVotedPlayers([]);
  }

  useEffect(() => {
    mixpanel.track('Voting Form Opened');
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="ion-padding-top">
          <div>
            <IonTitle size="large" className="ion-text-center">
              Select your 3, 2, 1
            </IonTitle>
          </div>
          <IonButtons slot="end">
            <IonButton onClick={handleDismiss}>
              <IonIcon slot="start" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PlayerList votedPlayers={votedPlayers} players={players} onVote={(player: ITeamPlayer) => handleVote(player)} />
      </IonContent>
      <IonFooter className="ion-padding">
        <IonButton expand="block" onClick={submitVote}>
          {loading ? <IonSpinner /> : 'Submit votes'}
        </IonButton>
      </IonFooter>
    </IonPage>
  )
}

export default VoteModal;
