import {
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
} from '@ionic/react';

import InviteForm from './InviteForm';

interface TeamInviteProps {
  onDismiss: () => void;
  onInvited: (player: any) => void;
  id: string;
}

const TeamInviteModal = ({ onDismiss, id, onInvited } : TeamInviteProps) => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Players</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <p>You can manually add players to your team by entering their details below. When they choose to login, they can do so using the provided email.</p>
          <InviteForm inviteId={id} onInvited={onInvited} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TeamInviteModal;
