import { useState, useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IonButton, IonSpinner } from '@ionic/react';

import { UserContext } from '../context/UserContext';

type UpdateFormFormValues = {
  email: string,
  firstName: string,
  lastName: string,
  notUnique: string
}

const UpdateForm = () => {

  const [loading, setLoading] = useState(false);

  const {register, handleSubmit, clearErrors, setError, formState: {errors}} = useForm<UpdateFormFormValues>();
  const history = useHistory();

  const { user, setUser} = useContext(UserContext);

  const onSubmit: SubmitHandler<UpdateFormFormValues> = async (data: any, e: any) => {
    e.preventDefault();
    setLoading(false);
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/users/${user?.email}/update`, data)
      setUser(res.data)
      history.push(`/tabs`)
    } catch (err) {
      setLoading(false);
      if (axios.isAxiosError(err) && err.response) {
        setError('notUnique', {
          type: 'server',
          message: err.response.data.message
        })
      }
    }
  }

  return (
    <form onSubmit={(e) => {
      clearErrors()
      handleSubmit(onSubmit)(e)
    }}>
      {errors.notUnique &&
        <div className="Banner Banner--error">
          {errors.notUnique.message}
        </div>
      }
      <div className="form__field">
        <label htmlFor="email">Email address</label>
        <input 
          id="email"
          placeholder="Enter your email address"
          type="email" 
          defaultValue={user?.email}
          readOnly
          {...register('email')}
          className="input"
          autoComplete="off"
          required />
      </div>
      <div className="form__field">
        <label htmlFor="email">First name</label>
        <input 
          id="firstName"
          placeholder="First name"
          {...register('firstName')}
          className="input"
          autoComplete="off"
          required />
      </div>
      <div className="form__field">
        <label htmlFor="email">Last name</label>
        <input 
          id="lastName"
          placeholder="Last name"
          {...register('lastName')}
          className="input"
          autoComplete="off"
          required />
      </div>
      <IonButton type="submit" expand="block" onClick={() => clearErrors()}>
        {loading ? <IonSpinner /> : 'Update account'}
      </IonButton>
    </form>
  )
}


export default UpdateForm;
