import { createContext, Dispatch, SetStateAction } from "react";

import { IUser, UserAuthSession } from '../models';

interface IUserContext {
  user: IUser | null,
  setUser: Dispatch<SetStateAction<IUser>>,
  userAuthSession: UserAuthSession;
  setUserAuthSession: Dispatch<SetStateAction<UserAuthSession>>
}

export const UserContext = createContext<IUserContext>({} as IUserContext);
