import { useState, useEffect } from 'react';
import axios from 'axios';

import { useForm } from 'react-hook-form';

import { isProd } from '../utility';

import mixpanel from 'mixpanel-browser';

import {
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonButtons,
  IonToolbar,
  IonSpinner,
  useIonToast,
} from '@ionic/react';

import {ISeason, IMatch} from '../models';

interface RequestModalProps {
  onDismiss: () => void;
  seasonId: string;
  teamName: string;
  teamId: string;
  playerId: string | null;
  recipients: string[];
  onCreateSeason: (season: ISeason) => void;
  onCreateMatch: (match: IMatch) => void;
}

const RequestModal = ({ onDismiss, seasonId, teamName, teamId, playerId, recipients, onCreateSeason, onCreateMatch } : RequestModalProps) => {

  const [loading, setLoading] = useState(false);

  const [presentToast] = useIonToast();

  const {register, handleSubmit} = useForm();

  const sendEmails = async () => {
    try {
      await axios.post('https://sqcgtut8v5.execute-api.ap-southeast-2.amazonaws.com/staging/sendEmail', {
        toEmailAddresses: recipients,
        subjectData: 'Time to vote',
        teamName,
        teamId,
        playerId,
      })
      onDismiss();
      presentToast('Requests sent', 3000);
    } catch {
      onDismiss();
      presentToast('Something went wrong', 3000);
    }
    setLoading(false);
  }

  const onSubmit = async (data: any, e: any) => {

    e.preventDefault();

    let match : any = {
      opponent: data.opponent,
      matchDatetime: new Date().toISOString(),
    }

    setLoading(true);

    if (!seasonId) {
      console.log('no active season, makign a new one');
      // if no season, create one with a placeholder match

      const matches = [match]

      const body = {
        teamId,
        matches
      }

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/season/create`, body)
      const season = {
        ...res.data,
        matches,
      }
      onCreateSeason(season);
      sendEmails();
      if (isProd()) {
        mixpanel.track('Votes requested', {
          "newSeason": true,
        });
      }
    } else {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/season/${seasonId}/matches`, { matches: [match] })
      match.id = res.data.id;
      onCreateMatch(match);
      sendEmails();
      if (isProd()) {
        mixpanel.track('Votes requested', {
          "newSeason": false,
        });
      }
    }

  }

  useEffect(() => {
    mixpanel.track('Requesting Votes', {
      "teamName": teamName,
    });
  }, [teamName])

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Request votes</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <div className="container">
          <form onSubmit={(e) => handleSubmit(onSubmit)(e)}>
            <div className="form__field">
              <label htmlFor="email">Who did you play?</label>
              <input 
                id="opponent"
                placeholder="Enter opponent's team name"
                {...register('opponent')}
                className="input"
                autoComplete="off"
                required />
            </div>
            <IonButton type="submit" expand="block">
              {loading ? <IonSpinner /> : 'Send requests'}
            </IonButton>
          </form>
        </div>
      </IonContent>
    </>
  )
}

export default RequestModal;
