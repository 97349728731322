import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
  IonItem,
  IonLabel,
  IonIcon,
  useIonAlert,
  useIonToast,
} from '@ionic/react';

import { ITeamDetails, ITeamPlayer } from '../models';

import { trashOutline } from 'ionicons/icons';

interface TeamInviteProps {
  onDismiss: () => void;
  onPlayerDeleted: (player: any) => void;
  team: ITeamDetails;
  adminEmail: string;
  playerId: string | null | undefined;
}

const TeamInvite = ({ onDismiss, team, onPlayerDeleted, playerId } : TeamInviteProps) => {

  const [present] = useIonAlert();
  const [presentToast] = useIonToast();

  const [playersState, setPlayersState] = useState<ITeamPlayer[]>(team?.playersList)

  const handlePlayerDeleted = async (player: ITeamPlayer) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/player/${player.playerId}/delete`)
      const newPlayers = playersState.filter(p => p.playerId !== player.playerId)
      setPlayersState(newPlayers);
      presentToast(`${player.firstName} ${player.lastName} removed successfully}`, 3000);
      onPlayerDeleted(player);
    } catch {
      presentToast(`There was a problem removing ${player.firstName}}`, 3000);
    }
  }

  const handleConfirmPlayerDelete = (player: ITeamPlayer) => {
    present({
      header: `Are you sure you want to remove ${player.firstName}?`,
      message: 'This player will lose all points and must be re-invited to vote again.',
      buttons: [
        'Cancel',
        { text: 'Remove', handler: () => handlePlayerDeleted(player) }
      ]
    })
  }

  const playersWithoutMe = playersState.filter(p => p.playerId !== playerId);
  
  const list = playersWithoutMe?.map((player) => {
    
    return (
      <IonItem key={`player-${player.playerId}`}>
        <IonIcon size="small" slot="end" icon={trashOutline} onClick={() => handleConfirmPlayerDelete(player)} />
        <IonLabel>
          { player.firstName + ' ' + player.lastName}
        </IonLabel>
      </IonItem>
    )
  })

  useEffect(() => {
    console.log('players state', playersState);
  }, [playersState])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit team</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ol className="line-list">
          {list}
        </ol>
      </IonContent>
    </IonPage>
  );
};

export default TeamInvite;
