import {
  IonItem,
  IonLabel,
} from '@ionic/react';

import { ITeamPlayer } from '../models';

interface PlayerListProps {
  players: ITeamPlayer[];
  votedPlayers: ITeamPlayer[];
  onVote: (player: ITeamPlayer) => void;
}

const PlayerListItem = ({ player, votes, onVote } : any) => {

  const handleClick = (player : ITeamPlayer) => {
    onVote(player, votes);
  }

  return (
    <IonItem
      className="PlayerList__item"
      key={`player-${player.playerId}`}
      onClick={() => handleClick(player)}
    >
      <IonLabel>
        { player.firstName + ' ' + player.lastName}
      </IonLabel>
      {votes > 0 && <span>{votes}</span>}
    </IonItem>
  )
}

const PlayerList = ({ players, onVote, votedPlayers } : PlayerListProps) => {

  const list = players?.map((player) => {

    const votes = votedPlayers.findIndex(v => v.playerId === player.playerId)
    
    return (
      <PlayerListItem player={player} onVote={onVote} votes={votes + 1}/>
    )
  })

  return (
    <ol className="PlayerList">
      {list}
    </ol>
  )
}

export default PlayerList;
