import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle } from '@ionic/react';

import { ITeam } from '../models';

interface TeamsListProps {
  teams: ITeam[]
}

const TeamList = ({teams} : TeamsListProps) => {

  const sorted = teams.sort((a, b) => {
    if (a.playersCount > b.playersCount) {
      return -1
    } else {
      return 1
    }
  })

  const list = sorted?.map((t: ITeam) => {
    return (
      <li key={`team-${t.teamId}`}>
        <IonCard className="TeamCard" routerLink={
            `/t/${t.teamId}?isAdmin=${t.isAdmin ? 'true' : 'false'}&playerId=${t.playerId}`
        }>
          <IonCardHeader>
            <IonCardTitle className="TeamList__name">{t.teamName}</IonCardTitle>
            {t.playersCount &&
              <IonCardSubtitle>{t.playersCount} player<i hidden={t.playersCount === 1}>s</i></IonCardSubtitle>
            }
          </IonCardHeader>
        </IonCard>
      </li>
    )
  });

  return (
    <>
      {!teams.length ? (
        <div className="desktop-center">
          <div className="ion-text-center">
            <h1>Welcome to Contendo</h1>
            <p>To get started, create your first team and then begin inviting your players.</p>
          </div>
        </div>
      ) : (
        <div>
          <h1>Your teams</h1>
          <ol className="unstyled TeamList">
            {list}
          </ol>
        </div>
      )}
    </>
  )

}

export default TeamList;
